import { render, staticRenderFns } from "./modifyDetail.vue?vue&type=template&id=fe4bc1a4&scoped=true&"
import script from "./modifyDetail.vue?vue&type=script&lang=js&"
export * from "./modifyDetail.vue?vue&type=script&lang=js&"
import style0 from "./modifyDetail.vue?vue&type=style&index=0&id=fe4bc1a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe4bc1a4",
  null
  
)

export default component.exports